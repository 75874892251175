export default {
  dots: false,
  infinite: false,
  centerMode: false,
  variableWidth: true,
  speed: 300,
  appendArrows: '.products--arrows',
  prevArrow: '<i class="fas fa-arrow-left slick-prev pointer"></i>',
  nextArrow: '<i class="fas fa-arrow-right slick-prev pointer"></i>',
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      }
    }
  ]
}
