const mobileWidth = 992;

const widths = [window.innerWidth];
if (window.screen?.width) {
  widths.push(window.screen?.width);
}
const width = Math.min(...widths);
const isMobile = () => width <= mobileWidth;

export default isMobile;
