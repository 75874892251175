import { Observable, timer, first, skip, sampleTime, Subject } from 'rxjs'

const loadImages = items => Promise.all(
  items
    .map(x => x.querySelector('img'))
    .map(
      image => new Promise(
        resolve => {
          const img = new Image()
          img.onload = () => resolve()
          img.src = image.src
        }
      )
    )
)

const renderItems = ({ carousel, items, itemWidth }) => {
  carousel.itemsWrapper.innerHTML = ''
  carousel.itemsWrapper.style.transition = 'transform 0s'
  carousel.itemsWrapper.style.transform = ''

  items.map(item => item.style.minWidth = `${itemWidth}px`)
  carousel.itemsWrapper.append(...items)
}

const calculateCarousel = slides => initialItems => carousel => ({
  ...carousel,
  items: Array.from(Array((Math.round(slides / initialItems.length) + 1)).keys())
    .flatMap(() => initialItems.map(child => child.cloneNode(true))),
  slides: [
    [1024, initialItems.length <= 3 ? initialItems.length : slides],
    [642, initialItems.length < 3 ? initialItems.length : 3],
    [0, initialItems.length < 2 ? initialItems.length : 2]
  ].find(breakpoint => document.documentElement.clientWidth >= breakpoint[0])[1]
})

const createBadges = carousel => ({
  ...carousel,
  items: (x = 0) => Array.from(Array(carousel.slides + 1).keys())
    .map(index => (index + x) % carousel.items.length)
    .map(index => {
      const item = carousel.items[index]
      const badge = item.getAttribute('data-badge')
      
      if (badge && !item.querySelector('.badge')) {
        const badgeElement = document.createElement('div')
        badgeElement.classList.add('badge')

        badgeElement.innerText = carousel.items[index].getAttribute('data-badge')
        carousel.items[index].append(badgeElement)
      }

      return carousel.items[index]
    })
})

const createCarousel = (element, initialItems) => (slides, $clock, timeInterval) => {
  [{ element, itemsWrapper: element.querySelector('.carousel__items') }]
    .map(carousel => ({ ...carousel, items: Array.from(carousel.itemsWrapper.children) }))
    .map(calculateCarousel(slides)(initialItems))
    .map(createBadges)
    .forEach(carousel => {
      const itemWidth = document.documentElement.clientWidth / carousel.slides

      loadImages(carousel.items()).then(() => {
        timer(0, timeInterval).subscribe($clock)

        $clock
          .pipe(first()).subscribe((x) => {
            carousel.element.style.display = 'block'

            renderItems({ carousel, items: carousel.items(x), itemWidth })
          })

        if (initialItems.length < 3) return

        $clock
          .pipe(skip(1))
          .subscribe((x) => {
            carousel.itemsWrapper.style.transition = 'transform 1s'
            carousel.itemsWrapper.ontransitionend = () =>
              renderItems({ carousel, items: carousel.items(x), itemWidth })
            carousel.itemsWrapper.style.transform = `translateX(-${itemWidth}px)`
          })
      })}
    )
}

const initCarousel = (slides, timeInterval) => element => {
  element.style.display = 'none'

  const $resizes = new Observable(subscriber => {
    new ResizeObserver(entry => subscriber.next(entry)).observe(document.body)
  })

  const initialItems = Array.from(element.querySelector('.carousel__items').children)

  let $clock = new Subject()

  $resizes
    .pipe(sampleTime(10))
    .subscribe(
      () => {
        $clock.complete()

        $clock = new Subject()

        createCarousel(element, initialItems)(slides, $clock, timeInterval)
      }
    )
}

$(() => {
  Array.from(document.getElementsByClassName('carousel')).map(initCarousel(6, 4000))
})
