const findCurrentAppointment = () => {
  if (!$('#all-appointments').length) {
    return false;
  }
  const current_wrapper = $('#current_appointment');

  current_wrapper.load(current_wrapper.data('source'), function( response, status, xhr ) {
    if ( status == 'success' && response.length > 1 ) {
      $('#current_appointment_wrapper').show();
    } else {
      $('#current_appointment_wrapper').hide();
    };
  });
}

export default findCurrentAppointment;
